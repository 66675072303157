/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 1, 2017 */

@font-face {
    font-family: 'Bitter';
    src: url('/assets/fonts/bitter-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/bitter-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/raleway-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/raleway-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/raleway-bolditalic-webfont.woff2') format('woff2'),
         url('/assets/fonts/raleway-bolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/raleway-italic-webfont.woff2') format('woff2'),
         url('/assets/fonts/raleway-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/raleway-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/raleway-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Signpainter';
    src: url('/assets/fonts/SignPainterHouseScriptRegular.ttf') format('ttf'),
         url('/assets/fonts/SignPainterHouseScriptRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
